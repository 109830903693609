import React from 'react'
//import videoBg from './src/images/AAIC-Google1.mp4';
import Footer from '../Footer.jsx';
//import FetchDataNoKey from '../FetchDataNoKey.jsx';
import TempApp from '../TempApp.jsx';
import PrayerTimesModern from '../PrayerTimesModern.jsx';
import Donate from '.././Donate.jsx';
import videoBg from '../../images/AAIC-Google1.mp4';
import Articles from '../Articles.jsx';
import Quran from '../Quran.jsx';
import ContactFormButton from '../ContactFormButton.jsx';
import MyCalendar from '../MyCalendar.jsx';
import Calendar from 'react-calendar';
import MonthlySchedule from '../MonthlySchedule.jsx';


const Home = () => {
  return (
    <div className='App'>
      <img src={require('../../images/aaic-pan1-careonside1.png')} alt='' />

     <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', marginLeft: '1vw' }}>
        <PrayerTimesModern />
        <div style={{marginTop: '1vw'}}><Quran/></div>
       <div style={{ marginTop: '5vw' }}><Donate /></div>
       
       <div style={{ marginTop: '50vw', marginLeft: '70vw', position: 'absolute', justifyContent: 'center'}}><Articles /><br /> <TempApp /> </div>
         </div>

         {/* <div style={{marginLeft: '10vw', marginTop: '150vw', marginRight: '50vw'}}> <MonthlySchedule /> </div> */}
    
      <div className='video_player' style={{ height: '37vw', marginLeft: '1vw', marginTop: '200vw', width: '95vw' }}>
        <video controls autoPlay loop muted className="bg-vid">
          <source src={videoBg} type="video/mp4"></source>
        </video>
       
      <br/><div style={{justifyContent: 'center', marginLeft: '40vw'}}><ContactFormButton/></div>
      <br/>  <div><Footer /></div>
      </div>

    </div>

  )
}
export default Home
