import React from 'react'

function Articles() {
  return (
    <div>
        <table border={'5px'}> 
        <tr style={{fontSize: '3.0vw',  borderBottom: '0.5px solid #ccc'}}> وَٱلشَّمْسُ تَجْرِى لِمُسْتَقَرٍّۢ لَّهَا ۚ ذَٰلِكَ تَقْدِيرُ ٱلْعَزِيزِ ٱلْعَلِيمِ ٣٨
        </tr>
<br/><tr style={{fontSize: '2.0vw', color: 'white', background: 'black', fontWeight: 'bolder'}}>The sun travels for its fixed term.<br></br> That is the design of the Almighty,<br/>All-Knowing.<br/> 36:38</tr>

<tr style={{fontSize: '2.10vw', fontWeight: 'bolder'}}>Dielli udhëton për afatin e tij të caktuar.<br/>Ky është plani i të Plotfuqishmit, të<br/> Gjithëdijshmit.</tr>
</table>
    </div>
  )
}

export default Articles