import { Link } from 'react-router-dom';
import ContactForm from './ContactForm';
import React, { useState } from 'react';

const ContactFormButton = () => {
    const [showComponent, setShowComponent] = useState(false);

    const handleClick = () => {
      setShowComponent(!showComponent);
    }; 

  return (
    
     <div >
      <button style={{color:'white', fontWeight: 'bolder', backgroundColor: 'goldenrod', borderRadius: '65px' }} onClick={handleClick}>&nbsp;Contact AAIC &nbsp;</button>
      {showComponent && <ContactForm />}
    </div>
   
  );
};

export default ContactFormButton;