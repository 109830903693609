import React from 'react'

 const About = () => {
  return (
    <center>
<br></br>
    Albanian American Islamic Center (AAIC)
    is a community center gain knowledge from
    Sunday school, a Banquet hall, and Funeral services.<br />
    <br />
     <span> Address: 5825 St Charles Rd, Berkeley, IL 60163<br />
      Phone: <a href="tel:+17085442609">708-544-2609 </a><br />
      </span><br />

     
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.173178025641!2d-87.91697768869031!3d41.88913256460397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4b478fcb5157%3A0x4695c19b7daa30b5!2s5825%20St%20Charles%20Rd%2C%20Berkeley%2C%20IL%2060163!5e0!3m2!1sen!2sus!4v1727318186200!5m2!1sen!2sus" 
      width="300" 
      height="250" 
      style={{border:"10"}} 
      allowFullscreen
      loading="lazy" 
      referrerPolicy="no-referrer-when-downgrade">
      </iframe>
     
    </center>
  )
}
export default About