import React from 'react'
import ContactForm from '../ContactForm'

const Contact = () => {
  return (
    <div>
<div style={{display: 'flex', marginLeft: '15vw', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
<ContactForm />
</div>
<div style={{marginTop: '-10vw', marginLeft: '27vw'}}>
 <p  >Contact Mohammed Shafiuddin <a href="tel:+13127149744">312-714-9744</a></p>
 </div>
</div>


  )
}

export default Contact